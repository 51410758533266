// this is shared mixins file for all components from the app,
// there you can easily add your own variables / imports or redefine
// existing without touching default packages for future updates

@import '../components/kit/core/mixins.scss'; // import KIT mixins
@import '../components/cleanui/styles/mixins.scss'; // import CLEANUI mixins
@import '../styles/mixins.scss'; // import Lux mixins

// $text: #000;
// $success: green;
$primary: #111111;

.jvectormap-zoomout,
.jvectormap-zoomin {
  width: 17px;
  height: 17px;
}

.ant-layout-sider-children {
  .scrollbar-container {
    padding-bottom: 40px;
  }
}

[data-kit-theme='default'] .ant-menu-item a {
  color: $gray-6;
}

[data-kit-theme='default'] .ant-menu-item-selected a {
  color: $blue-light;
  font-weight: bold;
}
