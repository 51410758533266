@import 'css/layout.scss';
@import 'css/utils.scss';
@import 'css/router.scss';

.tableContainer {
  td {
    white-space: normal;
  }
}

.dashboard-background {
  background: #f7f3f3;
  padding: 5px;
}

.annotation {
  width: 100%;
  height: 100%;
}

.noBorderRow > td {
  border-bottom: none !important;
}

.ant-table-fixed {
  table-layout: fixed;
}

.ant-form {
  .ant-input-disabled {
    color: $primary !important;
  }
}

.custom-tooltip {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 2px;
  width: max-content;
  background: white;
  text-align: center;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.clickable-row {
  cursor: pointer;
}

.hoverGrayBackground {
  &:hover {
    background: #dadada;
  }
}

.small-pagination-margin .ant-table-pagination.ant-pagination {
  margin-bottom: 0 !important;
}

.ant-tabs-tab-remove {
  padding: 0 !important;
}

.normal-cursor {
  cursor: default;
}

.normal-cursor:hover {
  color: rgba(0, 0, 0, 0.45) !important;
}
